import React from 'react'
import { MDXTag } from '@mdx-js/tag'




export default ({components, ...props}) => <MDXTag name="wrapper"  components={components}><MDXTag name="p" components={components}><MDXTag name="img" components={components} parentName="p" props={{"src":"/assets/specialfind_1614_1.jpg","alt":null}}></MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="a" components={components} parentName="p" props={{"href":"https://gabii.cast.uark.edu/data/browse/special_finds/1614"}}>{`https://gabii.cast.uark.edu/data/browse/special_finds/1614`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="img" components={components} parentName="p" props={{"src":"/assets/specialfind_1413_1.jpg","alt":null}}></MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="a" components={components} parentName="p" props={{"href":"https://gabii.cast.uark.edu/data/browse/special_finds/1413"}}>{`https://gabii.cast.uark.edu/data/browse/special_finds/1413`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="img" components={components} parentName="p" props={{"src":"/assets/000491.jpg","alt":null}}></MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="a" components={components} parentName="p" props={{"href":"https://gabii.cast.uark.edu/data/browse/special_finds/491"}}>{`https://gabii.cast.uark.edu/data/browse/special_finds/491`}</MDXTag></MDXTag></MDXTag>

export const _frontmatter = {"templateKey":"content-with-background","title":"Top Finds of Area C","backgroundImage":"/assets/tomb-11_assemblage.jpg","key":"top-finds-of-area-c","parentKey":"area-c"};

  